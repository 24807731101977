<template>
  <div class="container">
    <headerTop
      @handleChangeTime="handleChangeTime"
      :value="value"
      :currentBreadcrumblist="currentBreadcrumblist"
      :show="true"></headerTop>
    <div class="flexSb header">
      <div class="header_box1 flexSb posi">
        <div class="aaaa">
          <div class="num">
            {{ headerForm.excellent ? headerForm.excellent : "--" }}
            <span v-if="headerForm.excellent"> % </span>
          </div>
          <div class="num_title">优秀人数占比</div>
        </div>
        <img class="header_img" src="@/assets/img/177.png" alt="" />
        <div @click="handleTo('/studentlist')" class="detail flexSc">
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
      <div class="header_box1 header_box2 flexSb posi">
        <div class="aaaa">
          <div class="num">
            {{ headerForm.pass ? headerForm.pass : "--" }}
            <span v-if="headerForm.pass"> % </span>
          </div>
          <div class="num_title">合格人数占比</div>
        </div>
        <img class="header_img" src="@/assets/img/180.png" alt="" />
        <div @click="handleTo('/studentlist')" class="detail flexSc">
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
      <div class="header_box1 header_box3 flexSb posi">
        <div class="aaaa">
          <div class="num">
            {{ headerForm.excellent_edge ? headerForm.excellent_edge : "--" }}
            <span v-if="headerForm.excellent_edge"> % </span>
          </div>
          <div class="num_title">优秀边缘人数占比</div>
        </div>
        <img class="header_img" src="@/assets/img/161.png" alt="" />
        <div @click="handleTo('/studentlist')" class="detail flexSc">
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
      <div class="header_box1 header_box4 flexSb posi">
        <div class="aaaa">
          <div class="num">
            {{ headerForm.pass_edge ? headerForm.pass_edge : "--" }}
            <span v-if="headerForm.pass_edge"> % </span>
          </div>
          <div class="num_title">及格边缘人数占比</div>
        </div>
        <img class="header_img" src="@/assets/img/178.png" alt="" />
        <div @click="handleTo('/studentlist')" class="detail flexSc">
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
      <div class="header_box1 header_box5 flexSb posi">
        <div class="aaaa">
          <div class="num">
            {{ headerForm.fail ? headerForm.fail : "--" }}
            <span v-if="headerForm.fail"> % </span>
          </div>
          <div class="num_title">不及格人数占比</div>
        </div>
        <img class="header_img" src="@/assets/img/179.png" alt="" />
        <div @click="handleTo('/studentlist')" class="detail flexSc">
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="box2_title flexSb">
        <div>运动能力画像</div>
        <div @click="handleTo('/attainment')" class="more">More+</div>
      </div>

      <div class="flexSb bb">
        <div class="box2_item">
          <div class="flexSt box2_item2_a bb">
            <div class="title_type">人群：</div>
            <div class="flexSt title_item_box">
              <div
                @click="handleToClick(item, index)"
                class="title_item"
                v-for="(item, index) in typeList"
                :key="index"
                :class="{ active: item.id == currentType }">
                {{ item.title }}
              </div>
            </div>
          </div>

          <div class="box2_item_bot flexSt">
            <div>
              <div
                class="item_bot flexSb"
                :class="{ item_bot_a: currentType == 1 || currentType == 2 }">
                <div class="nam_num">
                  人数：<span>{{ leftInfo.excellent }}</span>
                </div>
                <div class="type_name">优秀</div>
              </div>
              <div
                class="item_bot1 item_bot flexSb"
                :class="{ item_bot_b: currentType == 1 || currentType == 3 }">
                <div class="nam_num">
                  人数：<span>{{ leftInfo.good }}</span>
                </div>
                <div class="type_name">良好</div>
              </div>
              <div
                :class="{ item_bot_c: currentType == 1 || currentType == 4 }"
                class="item_bot2 item_bot flexSb">
                <div class="nam_num">
                  人数：<span>{{ leftInfo.pass }}</span>
                </div>
                <div class="type_name">及格</div>
              </div>
              <div
                class="item_bot3 item_bot flexSb"
                :class="{ item_bot_d: currentType == 1 || currentType == 5 }">
                <div class="nam_num">
                  人数：<span>{{ leftInfo.fail }}</span>
                </div>
                <div class="type_name">不及格</div>
              </div>
            </div>
            <div class="right">
              <div class="flexSt bba">
                <img
                  v-if="currentType == 1 || currentType == 6"
                  class="na"
                  src="../../../assets/img/186.png"
                  alt="" />
                <img
                  v-else
                  class="na"
                  src="../../../assets/img/188.png"
                  alt="" />
                <div
                  :class="{
                    na_box: currentType == 1 || currentType == 6,
                  }"
                  class="na_box_a">
                  <div class="nam">优秀边缘</div>
                  <div
                    class="nam_num_a"
                    :class="{
                      nam_num: currentType == 1 || currentType == 6,
                    }">
                    人数：<span>{{ leftInfo.excellent_edge }}</span>
                  </div>
                </div>
              </div>

              <div class="flexSt aa">
                <img
                  v-if="currentType == 1 || currentType == 7"
                  class="na"
                  src="../../../assets/img/187.png"
                  alt="" />
                <img
                  v-else
                  class="na"
                  src="../../../assets/img/189.png"
                  alt="" />
                <div
                  :class="{
                    na_box: currentType == 1 || currentType == 7,
                  }"
                  class="na_box_a">
                  <div class="nam">及格边缘</div>
                  <div
                    class="nam_num_a"
                    :class="{
                      nam_num: currentType == 1 || currentType == 7,
                    }">
                    人数：<span>{{ leftInfo.pass_edge }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box2_item box2_item2" style="padding-right: 25px">
          <div class="flexSt box2_item2_a">
            <div class="title_type">运动能力：</div>
            <div class="flexSt title_item_box">
              <div
                @click="handleToClickAbility(item, index)"
                class="title_item2"
                v-for="(item, index) in sportsAbility"
                :key="index"
                :class="{ active: item.type == currentAbility }">
                {{ item.name }}
              </div>
            </div>
          </div>

          <div class="flexSb cc">
            <div class="sex_ratio" ref="sex_ratio"></div>
            <div class="sex_ratio" ref="grade_ratio"></div>
          </div>
        </div>
      </div>

      <div class="tip" style="display: none">
        根据您的筛选条件：{{ report }}
      </div>
    </div>
    <!-- style="height: 335px" -->
    <div class="box1">
      <div class="box2_title flexSb">
        <div>优秀人数变化趋势</div>
        <div class="more">More+</div>
      </div>
      <div class="flexSb">
        <div class="box1_item_1" ref="excellent"></div>
        <div class="box1_item_2" ref="range"></div>
        <div class="box1_item_2" ref="range_grade"></div>
      </div>
    </div>

    <div class="box1">
      <div class="box2_title flexSb">
        <div>合格人数变化趋势</div>
        <div class="more">More+</div>
      </div>
      <div class="flexSb">
        <div class="box1_item_1" ref="qualified"></div>
        <div class="box1_item_2" ref="range_qualified"></div>
        <div class="box1_item_2" ref="range_grade_qualified"></div>
      </div>
    </div>
    <div class="box1">
      <div class="box2_title flexSb">
        <div>优秀边缘人数变化趋势</div>
        <div class="more">More+</div>
      </div>
      <div class="flexSb">
        <div class="box1_item_1" ref="edge_excellent"></div>
        <div class="box1_item_2" ref="range_edge_excellent"></div>
        <div class="box1_item_2" ref="range_grade_edge_excellent"></div>
      </div>
    </div>
    <div class="box1">
      <div class="box2_title flexSb">
        <div>及格边缘人数变化趋势</div>
        <div class="more">More+</div>
      </div>
      <div class="flexSb">
        <div class="box1_item_1" ref="edge_qualified"></div>
        <div class="box1_item_2" ref="range_edge_qualified"></div>
        <div class="box1_item_2" ref="range_grade_edge_qualified"></div>
      </div>
    </div>
    <div class="box1">
      <div class="box2_title flexSb">
        <div>不及格人数变化趋势</div>
        <div class="more">More+</div>
      </div>
      <div class="flexSb">
        <div class="box1_item_1" ref="failed"></div>
        <div class="box1_item_2" ref="range_failed"></div>
        <div class="box1_item_2" ref="range_grade_failed"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import {
  excellent,
  range,
  range_grade,
  qualified,
  range_qualified,
  range_grade_qualified,
  edge_excellent,
  range_edge_excellent,
  range_grade_edge_excellent,
  edge_qualified,
  range_edge_qualified,
  range_grade_edge_qualified,
  failed,
  range_failed,
  range_grade_failed,
  sex_ratio,
  grade_ratio,
  sex_ratioData,
} from "../enums/teachingModel";
import {
  teaching,
  teachingTrend,
  getSportsList,
  sportsInfo,
} from "@/api/evaluation";
import headerTop from "../../components/newheaderTop/index.vue";
export default {
  components: {
    headerTop,
  },
  watch: {
    $route: function (to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  data() {
    return {
      currentBreadcrumblist: [
        {
          name: "teachingModel",
          pathname: "分层教学模型",
        },
      ],
      myChart1: null,
      report: "",
      leftInfo: {
        excellent: 0,
        excellent_edge: 0,
        fail: 0,
        good: 0,
        pass: 0,
        pass_edge: 0,
      },
      sportsList: [],
      yearName: "2022-2023学年",
      startTime: "",
      endTime: "",
      headerForm: {
        excellent: "",
        pass: "",
        excellent_edge: "",
        pass_edge: "",
        fail: "",
      },
      options: [],
      value: "",
      grade_ratio: grade_ratio,
      sex_ratio: sex_ratio,
      currentAbility: 0,
      sportsAbility: [
        {
          id: 1,
          name: "全部",
        },
      ],
      currentType: 1,
      typeList: [
        {
          id: 1,
          title: "全部",
        },
        {
          id: 2,
          title: "优秀",
        },
        {
          id: 3,
          title: "良好",
        },
        {
          id: 4,
          title: "及格",
        },
        {
          id: 5,
          title: "不及格",
        },
        {
          id: 6,
          title: "优秀边缘",
        },
        {
          id: 7,
          title: "及格边缘",
        },
      ],
      excellent: excellent,
      range: range,
      range_grade: range_grade,

      qualified: qualified,
      range_qualified: range_qualified,
      range_grade_qualified: range_grade_qualified,

      edge_excellent: edge_excellent,
      range_edge_excellent: range_edge_excellent,
      range_grade_edge_excellent: range_grade_edge_excellent,

      edge_qualified: edge_qualified,
      range_edge_qualified: range_edge_qualified,
      range_grade_edge_qualified: range_grade_edge_qualified,

      failed: failed,
      range_failed: range_failed,
      range_grade_failed: range_grade_failed,
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    this.$nextTick(() => {
      window.scrollTo(0, 0);
      this.WatchResize();
      this.$forceUpdate();
      // 对接口后删除
      this.handleInitsex_ratio();
      this.handleInitgrade_ratio();

      this.range_grade.title.text = "分班级优秀人数（" + this.yearName + "）";
      this.range.title.text = "分数段占比（" + this.yearName + "）";
      this.range_qualified.title.text = "等级人数占比（" + this.yearName + "）";
      this.range_grade_qualified.title.text =
        "分班级合格人数（" + this.yearName + "）";
      this.range_edge_excellent.title.text =
        "优秀边缘人数占比（" + this.yearName + "）";
      this.range_grade_edge_excellent.title.text =
        "分班级优秀边缘人数（" + this.yearName + "）";
      this.range_edge_qualified.title.text =
        "及格边缘人数占比（" + this.yearName + "）";
      this.range_grade_edge_qualified.title.text =
        "分班级及格边缘人数（" + this.yearName + "）";
      this.range_failed.title.text = "不及格人数占比（" + this.yearName + "）";
      this.range_grade_failed.title.text =
        "分班级不及格人数（" + this.yearName + "）";
      this.handleInitexcellent();
      this.handleInitrange();
      this.handleInitrangeGrade();

      this.handleInitrangeGradequalified();
      this.handleInitrangeQualified();
      this.handleInitqualified();
      this.handleInitrangeGradeEdgeExcellent();
      this.handleInitrangeEdgeExcellent();
      this.handleInitEdgeExcellent();
      this.handleInitrangeGradeEdgeQualified();
      this.handleInitrangeEdgeQualified();
      this.handleInitEdgeQualified();
      this.handleInitrangeGradeEdgeFailed();
      this.handleInitrangeEdgeFailed();
      this.handleInitEdgeFailed();
    });
  },
  methods: {
    WatchResize() {
      const that = this;
      // window.addEventListener("resize", function () {
      //   that.myChart1.resize();
      // });
    },
    handleTo(url) {
      this.$router.push(url);
    },
    async handleGetSportsList() {
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
      };
      await getSportsList(data).then((res) => {
        if (res.data.length > 0) {
          this.sportsAbility = res.data;
          this.sportsAbility.unshift({
            name: "全部",
            type: 0,
          });
          this.currentAbility = res.data[0].type;
          this.handleGetsportsInfo();
        }
      });
    },
    async handleGetsportsInfo() {
      console.log(22222222222222222222222222);
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
        sports_type: this.currentAbility,
        type: this.currentType,
      };
      await sportsInfo(data).then((res) => {
        console.log(res, "=数据 年级人数");

        this.leftInfo = res.data.type;
        this.report = res.data.report;
        let sports_type = res.data.sports_type.student_gender;
        let sex_ratioData = [
          { value: sports_type.boy, name: "男" },
          { value: sports_type.girl, name: "女" },
        ];

        // 性别
        this.sex_ratio.legend.formatter = function (name) {
          // 在这里编写自定义的格式化逻辑

          for (let i = 0; i < sex_ratioData.length; i++) {
            // value = grade_ratioData[i].value;
            console.log(i, name);
            if (name == sex_ratioData[i].name) {
              return name + sex_ratioData[i].value + "人";
            }
          }
        };
        this.sex_ratio.series[0].data = sex_ratioData;
        this.handleInitsex_ratio();

        // 年级
        let student_grade = [];
        res.data.sports_type.student_grade.forEach((item, index) => {
          student_grade.push({ value: item.num, name: item.grade });
        });
        this.grade_ratio.legend.formatter = function (name) {
          // 在这里编写自定义的格式化逻辑

          for (let i = 0; i < student_grade.length; i++) {
            // value = grade_ratioData[i].value;
            console.log(i, name);
            if (name == student_grade[i].name) {
              return name + student_grade[i].value + "人";
            }
          }
        };
        this.grade_ratio.series[0].data = student_grade;
        this.handleInitgrade_ratio();
      });
    },
    async handleGetteachingTrend(type) {
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
        type: type,
      };
      await teachingTrend(data).then((res) => {
        // 折线图
        let zxxAxis = [];
        let zxxSeries = [];
        res.data.graph.forEach((item, index) => {
          zxxAxis.push(item.year);
          zxxSeries.push(item.num);
        });

        // 饼图
        let circleData = [];
        res.data.circle_chart.forEach((item, index) => {
          circleData.push({ value: item.num, name: item.name });
        });

        // 柱图
        let barYxis = [];
        let barSeries = [];
        res.data.bar_chart.forEach((item, index) => {
          barYxis.push(item.name);
          barSeries.push(item.num);
        });
        // 优秀
        if (type == 1) {
          this.excellent.series[0].data = zxxSeries;
          this.excellent.xAxis.data = zxxAxis;

          this.range.series[0].data = circleData;
          this.range.title.text = "分数段占比（" + this.yearName + "）";

          this.range_grade.yAxis.data = barYxis;
          this.range_grade.series[0].data = barSeries;
          this.range_grade.title.text =
            "分班级优秀人数（" + this.yearName + "）";

          this.handleInitexcellent();
          this.handleInitrange(circleData);
          this.handleInitrangeGrade();
        }
        // 及格
        else if (type == 2) {
          this.qualified.series[0].data = zxxSeries;
          this.qualified.xAxis.data = zxxAxis;

          this.range_qualified.series[0].data = circleData;
          this.range_qualified.title.text =
            "等级人数占比（" + this.yearName + "）";

          this.range_grade_qualified.yAxis.data = barYxis;
          this.range_grade_qualified.series[0].data = barSeries;
          this.range_grade_qualified.title.text =
            "分班级合格人数（" + this.yearName + "）";

          this.handleInitrangeGradequalified();
          this.handleInitrangeQualified();
          this.handleInitqualified();
        }
        // 优秀边缘
        else if (type == 3) {
          this.edge_excellent.series[0].data = zxxSeries;
          this.edge_excellent.xAxis.data = zxxAxis;

          this.range_edge_excellent.series[0].data = circleData;
          this.range_edge_excellent.title.text =
            "优秀边缘人数占比（" + this.yearName + "）";

          this.range_grade_edge_excellent.yAxis.data = barYxis;
          this.range_grade_edge_excellent.series[0].data = barSeries;
          this.range_grade_edge_excellent.title.text =
            "分班级优秀边缘人数（" + this.yearName + "）";

          this.handleInitrangeGradeEdgeExcellent();
          this.handleInitrangeEdgeExcellent();
          this.handleInitEdgeExcellent();
        } // 及格边缘
        else if (type == 4) {
          this.edge_qualified.series[0].data = zxxSeries;
          this.edge_qualified.xAxis.data = zxxAxis;

          this.range_edge_qualified.series[0].data = circleData;
          this.range_edge_qualified.title.text =
            "及格边缘人数占比（" + this.yearName + "）";

          this.range_grade_edge_qualified.yAxis.data = barYxis;
          this.range_grade_edge_qualified.series[0].data = barSeries;
          this.range_grade_edge_qualified.title.text =
            "分班级及格边缘人数（" + this.yearName + "）";

          this.handleInitrangeGradeEdgeQualified();
          this.handleInitrangeEdgeQualified();
          this.handleInitEdgeQualified();
        } // 不及格
        else if (type == 5) {
          this.failed.series[0].data = zxxSeries;
          this.failed.xAxis.data = zxxAxis;

          this.range_failed.series[0].data = circleData;
          this.range_failed.title.text =
            "不及格人数占比（" + this.yearName + "）";
          this.range_grade_failed.yAxis.data = barYxis;
          this.range_grade_failed.series[0].data = barSeries;
          this.range_grade_failed.title.text =
            "分班级不及格人数（" + this.yearName + "）";
          this.handleInitrangeGradeEdgeFailed();
          this.handleInitrangeEdgeFailed();
          this.handleInitEdgeFailed();
        }
      });
    },
    handleChangeTime(val) {
      console.log(val, "=val111111111111111");
      this.startTime = val.startTime;
      this.endTime = val.endTime;
      this.yearName = val.value;
      this.handleGetteaching();
      this.handleGetSportsList();

      this.handleGetteachingTrend(1);
      this.handleGetteachingTrend(2);
      this.handleGetteachingTrend(3);
      this.handleGetteachingTrend(4);
      this.handleGetteachingTrend(5);
    },
    handleGetteaching() {
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
      };
      teaching(data).then((res) => {
        console.log(res);
        this.headerForm = res.data;
      });
    },
    handleInitgrade_ratio() {
      let chartDom = this.$refs.grade_ratio;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.grade_ratio);
    },
    handleInitsex_ratio() {
      let chartDom = this.$refs.sex_ratio;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }

      myChart.setOption(this.sex_ratio);
    },
    handleToClickAbility(item) {
      this.currentAbility = item.type;
      this.handleGetsportsInfo();
    },
    handleToClick(item) {
      this.currentType = item.id;
      this.handleGetsportsInfo();
    },
    // 不及格边缘
    handleInitrangeGradeEdgeFailed() {
      let chartDom = this.$refs.range_grade_failed;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.range_grade_failed);
    },
    handleInitrangeEdgeFailed() {
      let chartDom = this.$refs.range_failed;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.range_failed);
    },
    handleInitEdgeFailed() {
      let chartDom = this.$refs.failed;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.failed);
    },

    // 及格边缘
    handleInitrangeGradeEdgeQualified() {
      let chartDom = this.$refs.range_grade_edge_qualified;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.range_grade_edge_qualified);
    },
    handleInitrangeEdgeQualified() {
      let chartDom = this.$refs.range_edge_qualified;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.range_edge_qualified);
    },
    handleInitEdgeQualified() {
      let chartDom = this.$refs.edge_qualified;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.edge_qualified);
    },

    // 优秀边缘
    handleInitrangeGradeEdgeExcellent() {
      let chartDom = this.$refs.range_grade_edge_excellent;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.range_grade_edge_excellent);
    },
    handleInitrangeEdgeExcellent() {
      let chartDom = this.$refs.range_edge_excellent;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.range_edge_excellent);
    },
    handleInitEdgeExcellent() {
      let chartDom = this.$refs.edge_excellent;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.edge_excellent);
    },

    // 合格
    handleInitrangeGradequalified() {
      let chartDom = this.$refs.range_grade_qualified;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.range_grade_qualified);
    },
    handleInitrangeQualified() {
      let chartDom = this.$refs.range_qualified;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.range_qualified);
    },
    handleInitqualified() {
      let chartDom = this.$refs.qualified;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.qualified);
    },

    // 优秀
    handleInitrangeGrade() {
      let chartDom = this.$refs.range_grade;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.range_grade);
    },
    handleInitrange(val) {
      let chartDom = this.$refs.range;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      // let myChart = echarts.init(chartDom);

      myChart.setOption(this.range);
    },
    handleInitexcellent() {
      let chartDom = this.$refs.excellent;
      this.myChart1 = echarts.getInstanceByDom(chartDom);
      if (this.myChart1 == null) {
        //不存在，进行初始化操作
        this.myChart1 = echarts.init(chartDom);
      }
      this.myChart1.setOption(this.excellent);
    },
  },
};
</script>
<style lang="less" scoped>
body {
  overflow: hidden;
}
.container {
  min-height: 100%;
  overflow: scroll;
  padding: 20px;
  box-sizing: border-box;
}
/deep/.cell {
  color: #202020 !important;
}
.box2_title {
  color: rgba(16, 16, 16, 1);
  font-size: 20px;
  width: 100%;
  height: 52px;
  line-height: 52px;
  padding: 0 22px;
  border-bottom: 1px solid #e4e4e4;
  box-sizing: border-box;
}
.more {
  color: rgba(134, 134, 134, 1);
}
/deep/.more:hover {
  color: #226bff; /* 鼠标放上去的颜色 */
  cursor: pointer;
}
.box2 {
  // height: 500px;
  padding-bottom: 16px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);
  margin-top: 16px;
  .box2_item {
    width: 50%;
    height: 364px;
    padding: 31px 54px 0 34px;
    box-sizing: border-box;
    .box2_item2_a {
      height: 106px;
    }
    .title_type {
      height: 23px;
      line-height: 23px;
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      text-align: left;
    }
    .title_item_box {
      width: 85%;
      flex-wrap: wrap;
    }
    .title_item {
      cursor: pointer;
      margin-left: 13px;
      margin-bottom: 13px;
      width: 69px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 1);
      color: rgba(34, 107, 255, 1);
      font-size: 14px;
      text-align: center;
      font-family: Microsoft Yahei;
      border: 1px solid rgba(34, 107, 255, 1);
    }
    .active {
      background-color: rgba(34, 107, 255, 1);
      color: rgba(255, 255, 255, 1);
    }
    .box2_item_bot {
      align-items: flex-start;

      .item_bot {
        padding-left: 10px;
        box-sizing: border-box;
        width: 400px;
        height: 54px;
        // width: 380px;
        // height: 50px;

        background-image: url("../../../assets/img/193.png");
        background-size: 100% 100%;
        margin-bottom: 5px;

        /deep/ .nam_num {
          span {
            color: rgba(27, 113, 249, 0.1) !important;
          }
          color: rgba(27, 113, 249, 0.1) !important;
        }
        .type_name {
          // margin-right: 50px;
          margin-right: 118px;
          font-size: 14px;
          color: #ffffff;
        }
      }
      .item_bot_a {
        background-image: url("../../../assets/img/181.png");

        /deep/ .nam_num {
          color: #1b71f9 !important;
          span {
            color: #1b71f9 !important;
          }
        }
      }

      .item_bot1 {
        padding-left: 10px;
        box-sizing: border-box;
        // width: 369px;
        // height: 50px;
        width: 364px;
        height: 54px;
        background-image: url("../../../assets/img/184.png");
        margin-bottom: 5px;
        background-size: 100% 100%;
        .type_name {
          // margin-right: 40px;
          margin-right: 82px;
          font-size: 14px;
          color: #ffffff;
        }
        /deep/ .nam_num {
          color: rgba(15, 140, 222, 0.2) !important;
          span {
            color: rgba(15, 140, 222, 0.2) !important;
          }
        }
      }
      .item_bot_b {
        background-image: url("../../../assets/img/190.png");

        /deep/ .nam_num {
          color: #0f8cde !important;

          span {
            color: #0f8cde !important;
          }
        }
      }

      .item_bot2 {
        padding-left: 10px;
        box-sizing: border-box;
        // width: 358px;
        // height: 50px;

        width: 329px;
        height: 40px;
        background-image: url("../../../assets/img/192.png");
        margin-bottom: 5px;
        background-size: 100% 100%;
        .type_name {
          // margin-right: 30px;
          margin-right: 47px;
          font-size: 14px;
          color: #ffffff;
        }
        /deep/ .nam_num {
          color: rgba(130, 87, 214, 0.1) !important;
          span {
            color: rgba(130, 87, 214, 0.1) !important;
          }
        }
      }
      .item_bot_c {
        background-image: url("../../../assets/img/182.png");

        /deep/ .nam_num {
          color: #8257d6 !important;
          span {
            color: #8257d6 !important;
          }
        }
      }
      .item_bot3 {
        padding-left: 10px;
        box-sizing: border-box;
        // width: 347px;
        // height: 50px;
        width: 300px;
        height: 28px;
        background-image: url("../../../assets/img/191.png");
        background-size: 100% 100%;
        .type_name {
          font-size: 12px;
          color: #ffffff;
          // margin-right: 20px;
          margin-right: 14px;
        }
        /deep/ .nam_num {
          color: rgba(239, 132, 19, 0.1) !important;
          span {
            color: rgba(239, 132, 19, 0.1) !important;
          }
        }
      }
      .item_bot_d {
        background-image: url("../../../assets/img/183.png");

        /deep/ .nam_num {
          color: #ef8413 !important;
          span {
            color: #ef8413 !important;
          }
        }
      }
      .right {
        // margin-left: 10px;
        // margin-top: 25px;
        position: relative;
      }
      .na {
        // width: 51px;
        // height: 59px;
        width: 149px;
        height: 112px;
      }
      .type_name {
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
      }
      .na_box_a {
        width: 242px;
        height: 50px;
        margin-left: 10px;
        background-color: rgba(#fff, 0.5) !important;
        .nam {
          // width: 76px;
          // height: 50px;
          // text-align: center;
          // line-height: 50px;
          // color: rgba(255, 255, 255, 1);
          // font-size: 14px;
          // background-color: #e8e6fd;
          line-height: 26px;
          font-size: 14px;
          color: rgba(34, 107, 255, 0.3);
          background: rgba(34, 107, 255, 0.1);
          text-align: center;
          width: 71px;
          height: 26px;

          border-radius: 3px 3px 3px 3px;
        }
        .nam_num_a {
          // color: rgba(52, 52, 52, 1);
          // font-size: 18px;
          // padding-right: 20px;
          // box-sizing: border-box;
          // color: #f5f5f5 !important;
          // span {
          //   color: #f5f5f5 !important;
          // }
          margin-top: 5px;
          font-size: 14px;
          // color: #101010;
          color: rgba(16, 16, 16, 0.2);
        }
      }
      .na_box {
        // margin-left: 4px;
        // width: 242px;
        // height: 50px;
        // background-color: rgba(206, 226, 255, 1) !important;
        .nam {
          // width: 76px;
          // height: 50px;
          // text-align: center;
          // line-height: 50px;
          // background-color: rgba(24, 0, 236, 1) !important;
          // color: rgba(255, 255, 255, 1);
          // font-size: 14px;
          line-height: 26px;
          font-size: 14px;
          color: #226bff;
          background: rgba(34, 107, 255, 0.1);
          text-align: center;
          width: 71px;
          height: 26px;

          border-radius: 3px 3px 3px 3px;
        }
        .nam_1 {
          // background-color: #1d78fd !important;
        }
        .nam_num {
          // color: rgba(52, 52, 52, 1) !important;
          // font-size: 18px;
          // padding-right: 20px;
          // box-sizing: border-box;
          // span {
          //   font-size: 24px;
          //   color: rgba(23, 23, 23, 1) !important;
          // }
        }
      }
      .nam_num {
        color: rgba(52, 52, 52, 1) !important;
        font-size: 18px;
        padding-right: 20px;
        box-sizing: border-box;
        span {
          // font-size: 24px;
          // color: rgba(23, 23, 23, 1) !important;
        }
      }

      .aa {
        // margin-top: 55px;
        // margin-top: 5px;
        // position: absolute;
        // // left: -20px;
        // left: -50px;
        position: absolute;
        left: -118px;
        top: 119px;
        .na {
          // width: 72px;
          // height: 59px;
          width: 199px;
          height: 72px;
        }
        .nam {
          // width: 76px;
          // height: 50px;
          // text-align: center;
          // line-height: 50px;
          // background-color: rgba(24, 0, 236, 1) !important;
          // color: rgba(255, 255, 255, 1);
          // font-size: 14px;
          line-height: 26px;
          font-size: 14px;
          color: rgba(27, 164, 123, 1);
          background: rgba(27, 164, 123, 0.1);
          text-align: center;
          width: 71px;
          height: 26px;

          border-radius: 3px 3px 3px 3px;
        }
        .nam {
          background-color: rgba(27, 164, 123, 0.1);
        }
        .na_box_a {
          /deep/ .nam {
            .nam_num_a {
              // color: rgba(52, 52, 52, 1);
              // font-size: 18px;
              // padding-right: 20px;
              // box-sizing: border-box;
              // color: #f5f5f5 !important;
              // span {
              //   color: #f5f5f5 !important;
              // }
              // margin-top: 5px;
              // font-size: 14px;
              // // color: #101010;
              // color: rgba(16, 16, 16, 0.2);
            }
          }
          .nam {
            line-height: 26px;
            font-size: 14px;
            color: rgba(27, 164, 123, 0.3);
            background: rgba(27, 164, 123, 0.1);
            text-align: center;
            width: 71px;
            height: 26px;

            border-radius: 3px 3px 3px 3px;
          }
        }
        .na_box {
          .nam {
            // width: 76px;
            // height: 50px;
            // text-align: center;
            // line-height: 50px;
            // color: rgba(255, 255, 255, 1);
            // font-size: 14px;
            // background-color: #e8e6fd;
            line-height: 26px;
            font-size: 14px;
            color: rgba(27, 164, 123, 1);
            background: rgba(27, 164, 123, 0.1);
            text-align: center;
            width: 71px;
            height: 26px;

            border-radius: 3px 3px 3px 3px;
          }
          // background-color: #cee2ff !important;
        }
      }
      .bba {
        position: absolute;
        left: -68px;
      }
    }
  }
  .tip {
    padding: 0 34px;
    box-sizing: border-box;
    color: rgba(16, 16, 16, 1);
    font-size: 16px;
  }
  .box2_item2 {
    .box2_item2_a {
      flex-wrap: wrap;
      align-items: flex-start;
      height: 106px;
    }
    .title_item2 {
      cursor: pointer;
      padding: 0 5px;
      box-sizing: border-box;
      margin-left: 13px;
      margin-bottom: 13px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 1);
      color: rgba(34, 107, 255, 1);
      font-size: 14px;
      text-align: center;
      font-family: Microsoft Yahei;
      border: 1px solid rgba(34, 107, 255, 1);
    }
    .active {
      background-color: rgba(34, 107, 255, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
}
.cc {
  width: 100%;
  height: 210px;
  //   background-color: pink;
  .sex_ratio {
    width: 50%;
    height: 210px;
  }
}
.box1 {
  height: 400px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);
  margin-top: 16px;
  .box1_item_1 {
    width: 50%;
    height: 347px;
  }
  .box1_item_2 {
    width: 25%;
    height: 347px;
  }
}
.header {
  margin-top: 22px;
  .posi {
    position: relative;
    .detail {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 35px;
      background-color: rgba(21, 145, 165, 1);
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
      text-align: center;
      border-radius: 0 0 10px 10px;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      z-index: 99;
      .img_det {
        width: 20px;
        height: 20px;
        margin-left: 12px;
      }
    }
  }
  .header_box1 {
    cursor: pointer;
    box-sizing: border-box;
    height: 138px;
    width: 19.5%;
    border-radius: 10px;
    background-color: rgba(23, 162, 184, 1);
    padding: 0 0 0 30px;
    box-shadow: 0px 2px 6px 0px rgba(222, 222, 222, 1);
    position: relative;
    .num {
      color: rgba(255, 255, 255, 1);
      font-size: 35px;
      margin-bottom: 6px;
      padding-top: 23px;
      box-sizing: border-box;
      span {
        font-size: 18px;
      }
    }
    .num_title {
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      box-sizing: border-box;
      padding-bottom: 39px;
    }
    .header_img {
      width: 125px;
      height: 125px;
      position: absolute;
      right: 0;
    }
    .aaaa {
      z-index: 99;
    }
  }
  .header_box4 {
    background-color: #dc3545;
    .detail {
      background-color: rgba(198, 48, 62, 1);
    }
  }
  .header_box5 {
    background-color: #7f63f2 100%;
    .detail {
      background-color: #6644f3;
    }
  }
  .header_box3 {
    background-color: #ffc107;
    .detail {
      background-color: rgba(229, 173, 6, 1);
    }
  }
  .header_box2 {
    background-color: #28a745;
    .detail {
      background-color: rgba(36, 150, 62, 1);
    }
  }
  .header_box5 {
    background-color: #7f63f2;
    .detail {
      background-color: #6644f3;
    }
  }
}
.bb {
  align-items: flex-start;
}
</style>
